<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="mb-0 d-flex flex-column align-items-start">
            <h4 class="mb-0 font-size-18">Price Records {{ priceListId }}: {{ priceListMetadata ? priceListMetadata.filename : '' }}</h4>
            <p class="font-size-10">
              Uploaded By: {{ priceListMetadata ? priceListMetadata.uploader.name : ''}} |
              Supplier: {{ priceListMetadata ? priceListMetadata.supplier.name : ''}}
            </p>
          </div>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">
                Home
              </li>
              <li class="breadcrumb-item">
                Pricelists
              </li>
              <li class="breadcrumb-item">
                Pricelist {{ priceListId }}
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col-12">
        <PriceListRecordsGrid :priceListId="priceListId"/>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../layouts/horizontal.vue';
import PriceListRecordsGrid from "../components/pricelists/PriceListRecordsGrid";
import {mapState} from "vuex";

export default {
  name: 'PricelistRecords',
  components: {
    Layout,
    PriceListRecordsGrid
  },
  beforeMount() {
    this.fetchPriceListMetadata();
  },
  computed: {
    ...mapState({
      priceListMetadata: state => state.pricelists.currentPricelistMetadata
    })
  },
  methods: {
    fetchPriceListMetadata() {
      this.$store.dispatch('pricelists/fetchPriceListMetadata', this.priceListId);
    }

  },
  data() {
    return {
      priceListId: this.$route.params.id
    }
  }
}
</script>
